import { action, computed, observable } from 'mobx'

import {
  ActivityStatus,
  DeliveryStatus,
  SitePermitStatus,
} from '~/client/graph'
import {
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import { formatStatusToDisplay } from '~/client/src/shared/constants/DeliveryStatus'
import { LogisticsFilterType } from '~/client/src/shared/enums/LogisticsFilterType'
import LogisticsGroupingOption, {
  formsGroupingOptionList,
  hubGroupingOptionList,
} from '~/client/src/shared/enums/LogisticsGroupingOption'
import {
  getActivityStatusDisplayName,
  getFormStatusDisplayName,
} from '~/client/src/shared/localization/enumDisplayTexts'
import Activity from '~/client/src/shared/models/Activity'
import Announcement from '~/client/src/shared/models/Announcement'
import Delivery from '~/client/src/shared/models/Delivery'
import ILogisticItem, {
  LogisticItemApp,
} from '~/client/src/shared/models/ILogisticItem'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import SitePermit from '~/client/src/shared/models/Permit'
import BaseLogisticsList from '~/client/src/shared/stores/BaseLogisticsLists.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import FormCategoriesStore from '~/client/src/shared/stores/domain/FormCategories.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import {
  DEFAULT_ID_KEY,
  ITreeNodeObj,
} from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { logisticsDataKeys } from '~/client/src/shared/types/LogisticsDataKeys'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  appTypeToSortIndexMap,
  getAppSectionName,
} from '~/client/src/shared/utils/logisticsHelper'
import {
  groupingAttributeMapper,
  groupingCommonMapper,
} from '~/client/src/shared/utils/mappers'
import {
  EMPTY_STRING,
  LOCATION_SEPARATOR,
  NO_SPECIFIED,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

import MobileLogisticsStore from './MobileLogistics.store'

const unassignedValues = [UNASSIGNED, NO_SPECIFIED, EMPTY_STRING, NO_VALUE]

export default class MobileLogisticsListStore
  extends BaseMultiBandListStore<ILogisticItem>
  implements BaseLogisticsList
{
  @observable public isFiltersModalOpen: boolean = false
  @observable public isGroupByFilterShown: boolean = false
  private readonly collator = new Intl.Collator([], {
    numeric: true,
    sensitivity: 'accent',
  })

  public constructor(
    private readonly logisticsStore: MobileLogisticsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly companiesStore: CompaniesStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly sitePermitsStore: SitePermitsStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly formCategoriesStore: FormCategoriesStore,
    private openAnnouncement: (announcement: Announcement) => void,
    private openPermit: (permit: SitePermit) => void,
    private openActivity: (activity: Activity) => void,
    private openDelivery: (delivery: Delivery) => void,
    private readonly isPermitOnly?: boolean,
  ) {
    super(
      logisticsStore.filters,
      () => logisticsStore.allLogistics,
      [],
      DEFAULT_ID_KEY,
      false,
    )
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: ILogisticItem[] }
  } {
    const map = {}

    const groupingOptions = this.isPermitOnly
      ? formsGroupingOptionList
      : hubGroupingOptionList

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(logistic => {
        const groupNames: string[] = this.getCategoryIds(logistic, band)
        if (!groupNames.length) {
          groupNames.push(UNASSIGNED)
        }

        groupNames.forEach(groupName => {
          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }

          const index = bandMap[groupName].findIndex(
            l => l.startDate > logistic.startDate,
          )

          if (index === -1) {
            bandMap[groupName].push(logistic)
          } else {
            bandMap[groupName].splice(index, 0, logistic)
          }
        })
      })
    })
    return map
  }

  @computed
  public get rows(): ILWFCRow[] {
    const { groupingKey } = this.logisticsStore.filters

    return this.toBandTreeNodeRows(
      [groupingKey],
      null,
      '',
      0,
      false,
      this.sortTreeObjs,
    )
  }

  private sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      if (unassignedValues.includes(a.id)) {
        return 1
      }
      if (unassignedValues.includes(b.id)) {
        return -1
      }
      if (this.groupingKey === LogisticsGroupingOption.APP) {
        return appTypeToSortIndexMap[a.id] - appTypeToSortIndexMap[b.id]
      }
      if (this.groupingKey === LogisticsGroupingOption.DATE) {
        return parseInt(b.id, 10) - parseInt(a.id, 10)
      }

      return this.collator.compare(a.name, b.name)
    })
  }

  @action.bound
  public toggleFilters() {
    this.isFiltersModalOpen = !this.isFiltersModalOpen
  }

  @action.bound
  public toggleGroupBy() {
    this.isGroupByFilterShown = !this.isGroupByFilterShown
  }

  @action.bound
  public selectGroupBy(option: LogisticsGroupingOption) {
    this.filter.groupingKey = option
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndTimeToDisplay } = this.projectDateStore

    switch (currentBand) {
      case LogisticsGroupingOption.NONE:
        return [
          {
            id: LogisticsGroupingOption.NONE,
            name: LogisticsGroupingOption.NONE,
          },
        ]
      case LogisticsGroupingOption.APP:
        return [
          LogisticItemApp.ANNOUNCEMENT,
          LogisticItemApp.FORM,
          LogisticItemApp.DELIVERY,
          LogisticItemApp.SCHEDULE,
        ]
          .filter(app => this.shouldShowApp(app))
          .map(app => ({
            id: app,
            name: getAppSectionName(app),
            showEmpty: true,
          }))
      case LogisticsGroupingOption.COMPANY:
        return [
          ...this.filteredCollection
            .flatMap(logistic => logistic.companyIds)
            .map(companyId => {
              const company = this.companiesStore.getCompanyById(companyId)
              return {
                name: company?.name || UNASSIGNED,
                id: company?.id || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.TYPE:
        return [
          ...this.filteredCollection
            .flatMap(logistic => logistic.type)
            .map(type => {
              const permitType =
                this.permitTypesStore.getLastUpdatedTypeByType(type)
              return {
                name: permitType?.name || UNASSIGNED,
                id: type || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.RESPONSIBLE:
        return [
          ...this.filteredCollection
            .flatMap(logistic => logistic.responsibleContactIds)
            .map(userId => {
              const user = this.projectMembersStore.getById(userId)
              return {
                name: user?.fullName || UNASSIGNED,
                id: user?.id || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.DATE:
        return this.filteredCollection
          .map(logistic => logistic.startDate)
          .map(date => {
            return {
              name: getMonthDayAndTimeToDisplay(date) || UNASSIGNED,
              id: date?.toString() || UNASSIGNED,
            }
          })
      case LogisticsGroupingOption.LBS:
        return [
          ...this.locationAttributesStore.attributesWithDeletedWithoutEquipment.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.EQUIPMENT:
        return [
          ...this.locationAttributesStore.offloadingEquipmentsStore.listWithDeletedItems.map(
            groupingAttributeMapper,
          ),
          groupingCommonMapper(UNASSIGNED),
        ]

      case LogisticsGroupingOption.STATUS:
        return [
          ...Object.values(SitePermitStatus).map(s => ({
            id: s,
            name: getFormStatusDisplayName(s),
          })),
          ...Object.values(ActivityStatus).map(s => ({
            id: s,
            name: getActivityStatusDisplayName(s),
          })),
          ...Object.values(DeliveryStatus).map(s => ({
            id: s,
            name: formatStatusToDisplay(s),
          })),
          groupingCommonMapper(UNASSIGNED),
        ]
      case LogisticsGroupingOption.CATEGORY:
        return [
          ...this.filteredCollection
            .flatMap(logistic => this.getCategoryId(logistic))
            .map(categoryId => {
              const categoryName =
                this.formCategoriesStore.getNameById(categoryId)
              return {
                name: categoryName || UNASSIGNED,
                id: categoryId || UNASSIGNED,
              }
            }),
          groupingCommonMapper(UNASSIGNED),
        ]
    }
  }

  protected shouldShowApp(app: LogisticItemApp): boolean {
    const { state } = this.logisticsStore

    switch (app) {
      case LogisticItemApp.ANNOUNCEMENT:
        return !this.isPermitOnly
      case LogisticItemApp.FORM:
        return !state.isFormsDisabled
      case LogisticItemApp.DELIVERY:
        return !state.isDeliveriesDisabled && !this.isPermitOnly
      case LogisticItemApp.SCHEDULE:
        return !state.isTrackerDisabled && !this.isPermitOnly
    }
  }

  @computed
  public get categoryToInstancesMap(): {
    [categoryKey: string]: ILogisticItem[]
  } {
    const map = {}

    this.filteredCollection.forEach(logistic => {
      const categoryIds = this.getCategoryIds(logistic, this.groupingKey)
      categoryIds.forEach(formattedCategoryId => {
        if (map[formattedCategoryId]) {
          map[formattedCategoryId].push(logistic)
        } else {
          map[formattedCategoryId] = [logistic]
        }
      })
    })

    return map
  }

  private getCategoryIds(logistic: ILogisticItem, band: string) {
    switch (band) {
      case LogisticsGroupingOption.COMPANY:
        return logistic.companyIds?.map(
          id => this.companiesStore.getCompanyById(id)?.id || UNASSIGNED,
        )
      case LogisticsGroupingOption.RESPONSIBLE:
        const members = this.projectMembersStore.getByIds(
          logistic.responsibleContactIds,
        )
        return members.map(member => member.id || UNASSIGNED)
      case LogisticsGroupingOption.DATE:
        return [logistic.startDate.toString() || UNASSIGNED]
      case LogisticsGroupingOption.STATUS:
        return [logistic.status || UNASSIGNED]
      case LogisticsGroupingOption.APP:
        return [logistic.app]
      case LogisticsGroupingOption.TYPE:
        return [logistic.type]
      case LogisticsGroupingOption.LBS:
        return this.getLocationCategoryIds(logistic.locations)
      case LogisticsGroupingOption.EQUIPMENT:
        return this.getLocationCategoryIds(logistic.equipment)
      case LogisticsGroupingOption.NONE:
        return [LogisticsGroupingOption.NONE]
      case LogisticsGroupingOption.CATEGORY:
        return [this.getCategoryId(logistic)]
      default:
        return [UNASSIGNED]
    }
  }

  protected toRows(logistics: ILogisticItem[]) {
    return logistics.map(logistic => {
      const data = {
        [logisticsDataKeys.ID]: logistic,
      }

      return { data }
    })
  }

  public getFilteredCollectionExcludeFilter = (
    excludedFilters: Array<LogisticsFilterType | string> = [],
  ) => {
    let filteredCollection = this.isSearchFilterActive
      ? this.instancesInPeriodInterval.filter(this.searchFiltering)
      : this.instancesInPeriodInterval

    this.activeFilterTypes.forEach(filterType => {
      const shouldUseFilter = !excludedFilters.includes(filterType)

      if (!shouldUseFilter) {
        return
      }

      const filter = this.filter.fieldsMap[filterType]
      const { appliedFilterOptions } = filter

      filteredCollection = filteredCollection.filter(inst =>
        appliedFilterOptions.includes(inst.id),
      )
    })

    return filteredCollection
  }

  @action.bound
  public onRowClick(rowData: LWFCRowData) {
    const logistic = this.filteredCollection.find(
      logistic => logistic.id === rowData[logisticsDataKeys.ID].id,
    )

    switch (logistic.app) {
      case LogisticItemApp.FORM:
        const permit = this.sitePermitsStore.getFormById(logistic.entityId)
        this.openPermit(permit)
        break
      case LogisticItemApp.ANNOUNCEMENT:
        const announcement = this.logisticsStore.allAnnouncements.find(
          announcement => announcement.id === logistic.id,
        )
        this.openAnnouncement(announcement)
        break
      case LogisticItemApp.DELIVERY:
        const delivery = this.logisticsStore.allDeliveries.find(
          d => d.id === logistic.id,
        )
        this.openDelivery(delivery)
        break
      case LogisticItemApp.SCHEDULE:
        const activity = this.logisticsStore.allActivities.find(
          a => a.id === logistic.id,
        )
        this.openActivity(activity)
        break
    }
  }

  @computed
  public get filteredCollection(): ILogisticItem[] {
    return this.getFilteredCollectionExcludeFilter()
  }

  protected searchFiltering = (logistic: ILogisticItem) => {
    const key = this.filter.searchKey.toLowerCase()
    const entityValues: string[] = [
      ...logistic.locations.map(l => l.name),
      logistic.name,
      this.projectDateStore.getWeekdayMonthDayAndTimeToDisplay(
        logistic.startDate,
      ),
      this.projectDateStore.getWeekdayMonthDayAndTimeToDisplay(
        logistic.endDate,
      ),
      logistic.app,
      logistic.status,
      ...this.projectMembersStore
        .getByIds(logistic.responsibleContactIds)
        .map(u => u.fullName),
      ...logistic.companyIds.map(id =>
        this.companiesStore.getCompanyNameById(id),
      ),
    ]
    return entityValues.some(value => value?.toLowerCase().includes(key))
  }

  protected get isSearchFilterActive(): boolean {
    return !!this.filter.searchKey
  }

  public get instancesInPeriodInterval() {
    return this.logisticsStore.logisticItemsInPeriodInterval
  }

  @computed
  protected get activeFilterTypes(): string[] {
    const { fieldsMap = {} } = this.filter
    return Object.keys(fieldsMap).filter(
      filterType => fieldsMap[filterType].isFilterActive,
    )
  }

  private getLocationCategoryIds = (locations: LocationBase[]): string[] => {
    return Array.from(
      locations?.reduce(
        (set, l) => set.add(`${l.id}${LOCATION_SEPARATOR}${l.type}`),
        new Set<string>(),
      ),
    )
  }

  private getCategoryId(logistic: ILogisticItem) {
    if (logistic.app !== LogisticItemApp.FORM) return

    const form = this.logisticsStore.getFormById(logistic.entityId)
    const permitType = this.permitTypesStore.getPermitTypeById(form.typeId)
    return permitType?.categoryId || UNASSIGNED
  }

  public get columns(): ILWFCColumn[] {
    throw new Error('Method not implemented.')
  }
}
